
import { Vue, Component, Prop } from "vue-property-decorator";
import Descarga from "@/components/Descarga/Descarga.vue";

@Component({
  components: {
    Descarga
  }
})
export default class DescargaView extends Vue {
  private mounted() {}
}
